import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { BlogPostWrapper, BackgroundCover } from "../components/Style/blog-post"
import Seo from "../components/Seo"
import { LinkForNavMenu } from "../components/Universal/style"

export default class BlogPost extends Component {
  render() {
    const { data, pageContext } = this.props
    const post = data.markdownRemark
    const { previous, next } = pageContext
    const theTitle = post.frontmatter.title
    const theExcerpt = post.excerpt
    const theBackground = post.frontmatter.featuredImage.childImageSharp.fluid
    const theAuthor = post.frontmatter.author

    return (
      <Fragment>
        <Seo title={theTitle} description={theExcerpt} />

        <BlogPostWrapper>
          <Layout>
            <div id="blog-main">
              <h1>{theTitle}</h1>
              <div className="author-box">
                <p>{new Date(post.frontmatter.date).toLocaleDateString()}</p> | <p>By {theAuthor}</p>
              </div>

              <BackgroundCover background={theBackground}></BackgroundCover>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />

              <div className="prev-next-links">
                {previous && <LinkForNavMenu to={previous.fields.slug}>{previous.frontmatter.title}</LinkForNavMenu>}
                {next && <LinkForNavMenu to={next.fields.slug}>{next.frontmatter.title}</LinkForNavMenu>}
              </div>
            </div>
          </Layout>
        </BlogPostWrapper>
      </Fragment>
    )
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        author
      }
    }
  }
`
