import styled from "styled-components"

export const BlogPostWrapper = styled.div`
  #blog-main {
    background-color: white;
    padding: 80px;

    h1 {
      margin-bottom: 0;
    }
    h4 {
      font-size: 1.5rem;
      margin: 1rem;
    }
    ul {
      margin-left: 4rem;
    }
    .location-wrapper {
      margin-bottom: 30px;
      padding: 20px 30px;

      :nth-child(even) {
        background-color: #ddd;
      }
      .col-wrapper {
        display: flex;
        margin-top: 1rem;

        .col-8 {
          flex: 0 0 60%;
          margin-left: 1rem;
          p {
            margin: 1rem;
          }
          :first-child {
            margin-top: 0;
          }
        }
        .col-4 {
          flex: 1 auto;
          margin-left: 20px;
          img {
            width: 550px;
            max-width: 100%;
            border-radius: 4px;
          }
        }
      }
    }
    p a {
      text-transform: none;
      font-size: 17px;
      color: crimson;
      font-weight: bolder;
      text-decoration: underline;

      :hover {
        opacity: 0.7;
      }
    }
    .author-box {
      display: flex;
      align-items: center;
      margin: 10px 0 40px 0;

      p {
        margin: 0;
        padding: 0 10px;

        :first-child {
          padding-left: 0;
        }
      }
    }
    .prev-next-links {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid gray;
      padding-top: 20px;
      margin-top: 60px;

      a {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 830px) {
    #blog-main .location-wrapper .col-wrapper {
      flex-direction: column;

      .col-4 {
        order: -1;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 610px) {
    #blog-main {
      padding: 40px 20px;

      h1 {
        font-size: 28px;
      }
      h3 {
      }
    }
  }
  @media (max-width: 400px) {
    #blog-main .location-wrapper {
      padding: 20px 10px;
    }
  }
`

export const BackgroundCover = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url("${(props) => props.background.src}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 50px;
`
